<template>
  <div class="smart-components-wrapper" :key="$route.path">
    <div
        v-for="(component, componentKey) in loopComponents"
        :key="componentKey"
    >
      <component
          :is="component.selectedComponent"
          :contentData="getContentData(component)"
          v-if="contentStatus === 'done'"
      />
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  defineAsyncComponent,
  nextTick,
  computed,
  watch,
  onMounted,
  ref,
} from "vue";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import { mapState } from "pinia";
import { useMenuStore } from "../../store/pinia/menu/index";
import { handleResize } from "@/composables/resizeHandler";
import clearTimeoutHandler from "@/composables/clearTimeoutHandler";

export default defineComponent({
  components: {},
  name: "smartComponentsLoader",
  props: {},
  data() {
    return {
      top: false,
    };
  },

  computed: {
    ...mapState(useMenuStore, [
      "current_content_request_status",
      "indx",
      "selected_menu",
      "current_content",
    ]),
    contentStatus() {
      return this.current_content_request_status;
    },
    smartComponentsState() {
      return this.indx.smartComponents;
    },
    loopComponents() {
      let smartComponents = this.$get(
          this.selected_menu,
          "secondary_data"
      );

      let singleOrList =
          this.$get(this.selected_menu, "routeParams.singleview") ||
          this.$get(this.selected_menu, "routeParams.apview")
              ? "single"
              : "list";

      let componentTitles = [];
      let ret = [];
      for (let i in smartComponents) {
        if (this.$get(smartComponents[i], "enabled") != 1) continue;
        if (
            parseInt(
                this.$get(smartComponents[i], "childComponent")
            ) === 1
        )
          continue;

        //// exclude component depend on menu settings
        if (
            singleOrList === "list" &&
            this.$get(smartComponents[i], "listLayout") != 1
        ) {
          continue;
        } else if (
            singleOrList === "single" &&
            this.$get(smartComponents[i], "singleLayout") != 1
        ) {
          continue;
        }

        ret.push(smartComponents[i]);
        componentTitles.push(smartComponents[i].selectedComponent);
      }
      return ret;
    },
  },
  methods: {
    getContentData(component) {
      let selectedComponent = this.$get(component, "selectedComponent");
      let componentUnicId = this.$get(component, "unicId");
      let primary = this.$get(component, "primary");
      let res = {};
      // @Delete in if (primary) checking
      if (
          primary &&
          this.$get(this.current_content, [
            "primary",
            "data",
            "listCount",
          ])
      ) {
        res = this.$get(this.current_content, ["primary"]);
      }

      if (!this.$get(res, "conf.enabled")) {
        res.conf = this.$get(this.current_content, [
          "secondary",
          selectedComponent + "_" + componentUnicId,
          "conf",
        ]);
      }
      if (!this.$get(res, "data.listCount")) {
        res.data = this.$get(this.current_content, [
          "secondary",
          selectedComponent + "_" + componentUnicId,
          "data",
        ]);
      }

      return res;
    },
  },

  watch: {
    contentStatus: {
      immediate: true,
      deep: true,
      handler(ns) {
        if (ns === "done") {
          let components = this.$get(this.indx, "smartComponents");

          for (let i in components) {
            this.$options.components[i] = defineAsyncComponent({
              loader: () =>
                  import(
                  "../../components/smartComponents/" +
                  i +
                  "/" +
                  i
                      ),
              // loadingComponent: `<template><div>Loading</div></template>`,
            });
          }
        }
      },
    },
  },

  setup() {
    // STORE
    const store = useMenuStore();

    // COMPOSABLES
    const { windowWidth } = handleResize();

    // COMPUTED
    const currentContentStatus = computed(
        () => store.current_content_request_status
    );
    const isMainSliderLoaded = computed(() => store.isMainSliderLoaded);

    // REFS
    const transitioningStepOneTO = ref(null);
    const transitioningStepTwoTO = ref(null);

    // store.isDefaultPageTransitioningStepOne = true;

    watch(
        [currentContentStatus, isMainSliderLoaded],
        async (ns) => {
          if (!store.selected_menu) return;
          store.isPageTransitioningStepTwo = false;
          store.isPageTransitioningStepOne = true;

          if (store.selected_menu?.url !== "home") {
            store.isMainSliderLoaded = true;
          } else {
            store.isMainSliderLoaded = false;
          }
          // todo change true to ns[1]
         if (
              ns[0] === "done" &&
              store.isPageTransitioningStepOne &&
             ns[1]
          ) {
            document.querySelector("body").classList.add("modal-open");
            clearTimeoutHandler(transitioningStepOneTO.value);
            clearTimeoutHandler(transitioningStepTwoTO.value);
            transitioningStepOneTO.value = window.setTimeout(() => {
              store.isPageTransitioningStepOne = false;
              store.isPageTransitioningStepTwo = true;
            }, 500);
            transitioningStepTwoTO.value = window.setTimeout(() => {
              store.isPageTransitioningStepTwo = false;
              // store.disableScrollStatus = false;

              document
                  .querySelector("body")
                  .classList.remove("modal-open");
            }, 1200);
          }
        },
        { immediate: true }
    );

    // METHODS
    const pageTransitionHandlerStepOne = async () => {
      await nextTick();

      return new Promise((resolve) => {
        store.isPageTransitioningStepOne = true;
        store.disableScrollStatus = true;

        setTimeout(() => {
          store.scrollToTopLoco = !store.scrollToTopLoco;

          resolve();
        }, 500);
      });
    };

    // const defaultPageTransitionHandlerStepOne = () => {

    //   store.disableScrollStatus = true;

    //   setTimeout(() => {
    //     store.isDefaultPageTransitioningStepOne = false;
    //     store.isPageTransitioningStepTwo = true;
    //   }, 1700);
    //   setTimeout(() => {
    //     store.disableScrollStatus = false;
    //     store.isPageTransitioningStepTwo = false;
    //   }, 2800);
    // }

    // ROUTE GUARDS
    onBeforeRouteUpdate(async (to, from) => {

      if (
          Object.entries(to.query).length ||
          (Object.entries(from.query).length &&
              to.fullPath.includes("apartments")) ||
          to.fullPath.includes("apview")
      )
        return true;

      await pageTransitionHandlerStepOne();

      return true;
    });
    onBeforeRouteLeave(async (to, from) => {
      if (
          Object.entries(to.query).length ||
          (Object.entries(from.query).length &&
              to.fullPath.includes("apartments")) ||
          to.fullPath.includes("apview")
      )
        return true;
      await pageTransitionHandlerStepOne();
      store.scrollToTopLoco = !store.scrollToTopLoco;

      return true;
    });

    return {
      store,
    };
  },
});
</script>
<style lang="scss">
.smart-components-wrapper {
  overflow: hidden;
  min-height: 100vh;

  @include mq(mobile-sm) {
    padding-top: 27px;
  }
}
</style>