/**
 * @author Irakli Kandelaki
 */

import { ref, onBeforeMount, onUnmounted } from "vue";
import type { Ref } from "vue";

export const handleResize = (): {
    windowWidth: Ref<number>;
    windowHeight: Ref<number>;
} => {
    const windowWidth: Ref<number> = ref(window.innerWidth);
    const windowHeight: Ref<number> = ref(window.innerHeight);
    const setNewSizes = (): void => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
    };
    onBeforeMount(() => {
        window.addEventListener("resize", setNewSizes);
    });
    onUnmounted(() => {
        window.removeEventListener("resize", setNewSizes);
    });

    return {
        windowHeight,
        windowWidth,
    };
};
